import { SignOut } from '../actions/audit'
import { CategoryCreatorActions } from '../actions/category-creator'
import {
	GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS,
	SIGN_OUT,
} from '../actions/types'
import { categoryMapObj } from '../constants'

export type CategoryCreatorState = {
	categoryMap: categoryMapObj[]
}

const initialState: CategoryCreatorState = {
	categoryMap: [
		{
			id: 0,
			name: '',
			slug: '',
			description: '',
			count: 0,
			url: '',
			products: [],
			subCategory: [],
		},
	],
}

export const categoryMapReducer = (
	state = initialState,
	action: CategoryCreatorActions,
): typeof initialState => {
	switch (action.type) {
		case GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS:
			return {
				...state,
				categoryMap: [...action.payload.data],
			}
		case SIGN_OUT:
			return initialState
		default:
			return state
	}
}

export const categoryMapSelector = {
	getCategoryMap: (state = initialState): CategoryCreatorState['categoryMap'] =>
		state.categoryMap,
}
