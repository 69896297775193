import { API } from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import { SignOut } from './audit'
import {
	GET_PRODUCT_CATEGORY_TREE_MAP,
	GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS,
} from './types'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P }
}

type GetProductCategoryTreeMap = Action<
	typeof GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS,
	any
>

export type CategoryCreatorActions = GetProductCategoryTreeMap | SignOut

export const getProductCategoryTreeMap = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_PRODUCT_CATEGORY_TREE_MAP,
	)
	return {
		type: GET_PRODUCT_CATEGORY_TREE_MAP,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
