/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useEffect } from 'react'
import { connect } from 'react-redux'
import {
	withTranslation,
	useTranslation,
	WithTranslation,
	Trans,
} from 'react-i18next'

import { EHIGHLIGHT, ROUTER_PATH } from '../../../constants'
import Button from '../../../shared/components/Button'
import { tutorialSelectors } from '../../../reducers/tutorial'

import { TutorialState } from '../../../reducers/tutorial'
import { tutorialActions } from '../../../actions'
import { history } from '../../../reducers'

type Props = {
	tutorial: TutorialState
	changeTutorialField: any
	closeModal: any
} & PropsWithChildren<WithTranslation>

const NewUserTutorial = ({
	tutorial,
	changeTutorialField,
	closeModal,
}: Props): JSX.Element | null => {
	const { t } = useTranslation()

	const tutorialOptions = {
		0: { highlighted: EHIGHLIGHT.NONE, modalAlignment: 'center' },
		1: { highlighted: EHIGHLIGHT.SIDE_NAV, modalAlignment: 'center' },
		2: { highlighted: EHIGHLIGHT.DASHBOARD_BUTTON, modalAlignment: 'center' },
		3: { highlighted: EHIGHLIGHT.TASKS_BUTTON, modalAlignment: 'center' },
		4: { highlighted: EHIGHLIGHT.TOOLS_BUTTON, modalAlignment: 'center' },
		5: {
			highlighted: EHIGHLIGHT.ADS_VISUALISATION_BUTTON,
			modalAlignment: 'center',
		},
		6: {
			highlighted: EHIGHLIGHT.KEYWORD_PLANNER_BUTTON,
			modalAlignment: 'center',
		},
		7: {
			highlighted: EHIGHLIGHT.KEYWORD_RANKING_BUTTON,
			modalAlignment: 'center',
		},
		8: {
			highlighted: EHIGHLIGHT.TECHNICAL_AUDIT_BUTTON,
			modalAlignment: 'center',
		},
		9: {
			highlighted: EHIGHLIGHT.PROJECT_SETTINGS_BUTTON,
			modalAlignment: 'center',
		},
		10: {
			highlighted: EHIGHLIGHT.PROFILE_BUTTON,
			modalAlignment: 'center',
		},
		11: {
			highlighted: EHIGHLIGHT.SUBSCRIPTION_BUTTON,
			modalAlignment: 'center',
		},
		12: {
			highlighted: EHIGHLIGHT.CREATE_PROJECT_BUTTON,
			modalAlignment: 'center',
		},
		13: {
			highlighted: EHIGHLIGHT.TUTORIAL_BUTTON,
			modalAlignment: 'center',
		},
	}

	type TutorialKeys = keyof typeof tutorialOptions

	useEffect(() => {
		changeTutorialField(
			tutorialOptions[
				tutorial.currentStep as unknown as TutorialKeys
			] as unknown as typeof tutorialOptions,
		)
		return () => {
			changeTutorialField({ highlighted: EHIGHLIGHT.NONE })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tutorial.currentStep])

	const handleCloseModal = () => {
		changeTutorialField({ firstTime: false, highlighted: 0 })
		closeModal()
		history.push(ROUTER_PATH.OVERVIEW)
	}

	const introductionStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{t('tutorial.first time user tutorial.step 1 headline')}
			</h6>
			<p>{t('tutorial.first time user tutorial.step 1 text')}</p>
		</div>
	)
	useEffect(() => {}, [])

	const sidenavStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 2 headline')}
			</h6>
			<p>{t('tutorial.first time user tutorial.step 2 text')}</p>
		</div>
	)
	const dashboardStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 3 headline')}
			</h6>
			<p>
				<Trans
					t={t}
					i18nKey={'tutorial.first time user tutorial.step 3 text'}
				/>
			</p>
		</div>
	)

	const tasksStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 4-1 headline')}
			</h6>
			<p>{t('tutorial.first time user tutorial.step 4-1 text')}</p>
		</div>
	)
	const toolsStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 5 headline')}
			</h6>
			<p>{t('tutorial.first time user tutorial.step 5 text')}</p>
		</div>
	)
	const adsVisualisationStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 6 headline')}
			</h6>
			<p> {t('tutorial.first time user tutorial.step 6 text')}</p>
		</div>
	)
	const keywordPlannerStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 7 headline')}
			</h6>
			<p> {t('tutorial.first time user tutorial.step 7 text')}</p>
		</div>
	)
	const keywordRankinStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 8 headline')}
			</h6>
			<p> {t('tutorial.first time user tutorial.step 8 text')}</p>
		</div>
	)
	const technicalAuditStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 9 headline')}
			</h6>
			<p> {t('tutorial.first time user tutorial.step 9 text')}</p>
		</div>
	)
	const projectSettingsStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 10 headline')}
			</h6>
			<p> {t('tutorial.first time user tutorial.step 10 text')}</p>
		</div>
	)
	const profileSettingsStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 11 headline')}
			</h6>
			<p> {t('tutorial.first time user tutorial.step 11 text')}</p>
		</div>
	)
	const subscriptionStep = () => (
		<div className='project-tutorial-holder'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 12 headline')}
			</h6>
			<p> {t('tutorial.first time user tutorial.step 12 text')}</p>
		</div>
	)
	const createProjectStep = () => (
		<div className='project-tutorial-holder last-step'>
			<h6 className='project-tutorial-header'>
				{' '}
				{t('tutorial.first time user tutorial.step 13 headline')}
			</h6>
			<p>
				<Trans
					t={t}
					i18nKey={'tutorial.first time user tutorial.step 13 text'}
				/>
			</p>
		</div>
	)

	const howToSeeTutorialAgainStep = () => {
		history.push(ROUTER_PATH.PROFILE)
		return (
			<div className='project-tutorial-holder last-step'>
				<h6 className='project-tutorial-header'>
					{' '}
					{t('tutorial.first time user tutorial.step 14 headline')}
				</h6>
				<Trans
					t={t}
					i18nKey={'tutorial.first time user tutorial.step 14 text'}
				/>
			</div>
		)
	}

	switch (tutorial.currentStep) {
		case 0:
			return introductionStep()
		case 1:
			return sidenavStep()
		case 2:
			return dashboardStep()
		case 3:
			return tasksStep()
		case 4:
			return toolsStep()
		case 5:
			return adsVisualisationStep()
		case 6:
			return keywordPlannerStep()
		case 7:
			return keywordRankinStep()
		case 8:
			return technicalAuditStep()
		case 9:
			return projectSettingsStep()
		case 10:
			return profileSettingsStep()
		case 11:
			return subscriptionStep()
		case 12:
			return createProjectStep()
		case 13:
			return howToSeeTutorialAgainStep()
		default:
			return null
	}
}

export default connect(
	(state: any) => ({
		tutorial: tutorialSelectors.getTutorial(state.tutorial),
	}),
	dispatch => ({
		changeTutorialField: (payload: any) =>
			dispatch(tutorialActions.changeTutorialField(payload)),
	}),
)(withTranslation()(NewUserTutorial))
