/**
 * Main container
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

// WORK INSTRUCTIONS:
// - Please do not remove the above header. It protects the IPR intrests of our company.
// - Please replace the author name with your own full real name.

import React, { Suspense, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

// Components
import ErrorMessage from './UI/ErrorMessage'
import Message from './UI/Message'
import { userSelectors } from '../reducers'
import ErrorBoundary from '../shared/hocs/ErrorBoundary'
import Tutorial from './Tutorial/Tutorial'
import NewVersionChecker from '../shared/hocs/NewVersionChecker'
import Router from './Router'
import LoadingScreen from '../shared/components/LoadingScreen'
import { getNotifications } from '../helpers/firebaseHelpers'

const App = () => {
	const { isAuthenticated, firebaseToken } = useSelector(state => ({
		isAuthenticated: userSelectors.isAuthenticated(state.user),
		firebaseToken: state.user.firebaseToken,
	}))

	const dispatch = useDispatch()

	useEffect(() => {
		if (firebaseToken) {
			const unsubscribe = getNotifications(firebaseToken, dispatch)

			return () => {
				if (typeof unsubscribe === 'function') {
					unsubscribe()
				}
			}
		}
	}, [firebaseToken])

	return (
		<Suspense fallback={<LoadingScreen />}>
			<ErrorBoundary>
				<Helmet titleTemplate='Dashboa | %s' defaultTitle='Dashboa'>
					<meta name='description' content='Dashboa application' />
					<link rel='icon' href='../assets/images/logo-icon.png' />
				</Helmet>
				<LoadingScreen>
					<NewVersionChecker>
						<Router isAuthenticated={isAuthenticated} />
						<Tutorial />
						<ErrorMessage />
						<Message />
					</NewVersionChecker>
				</LoadingScreen>
			</ErrorBoundary>
		</Suspense>
	)
}

export default App
