import React, { useState } from 'react'
import { INotification } from '../../actions/message'
import { IconButton } from '@mui/material'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { DASHBOA_PRIMARY_COLOR } from '../../constants'
import moment from 'moment'
import _ from 'lodash'
import { updateNotification } from '../../helpers/firebaseHelpers'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Delete } from '@mui/icons-material'

type Props = {
	notifications: INotification[]
	firebaseToken: string
}

const Notifications = ({
	notifications,
	firebaseToken,
}: Props): JSX.Element => {
	const { t } = useTranslation()
	const newNotifications = notifications.filter(
		notification => !notification.seen,
	)
	const dispatch = useDispatch()
	const [showNotifications, setShowNotifications] = useState(false)
	const [showDetailsIndex, setShowDetailsIndex] = useState(-1)

	return (
		<div className='notifications'>
			<div className='notifications__icon'>
				<IconButton onClick={() => setShowNotifications(prev => !prev)}>
					<NotificationsActiveIcon
						sx={{ fontSize: '3rem', color: DASHBOA_PRIMARY_COLOR }}
					/>
				</IconButton>

				<div
					className={`notifications__icon__amount-bubble ${
						newNotifications.length > 0 ? '' : 'hide'
					}`}
				>
					<p>{newNotifications.length}</p>
				</div>
			</div>

			{showNotifications && (
				<div className='notifications__list'>
					{notifications.length ? (
						<div
							className={'notifications__list__item'}
							onClick={async () => {
								await updateNotification(firebaseToken, [], dispatch)
							}}
						>
							<p className='notifications__list__item__clear-all'>
								{t('common.clear all')}
							</p>
						</div>
					) : (
						<div className={'notifications__list__item'}>
							<p className='notifications__list__item__clear-all'>
								{t('common.no notifications')}
							</p>
						</div>
					)}
					{notifications.map((notification, index) => {
						const isCurrentIndex = showDetailsIndex === index
						const seen = Boolean(notification.seen)
						return (
							<div
								className={`notifications__list__item ${
									seen ? '' : 'not-seen'
								}`}
								onClick={async () => {
									setShowDetailsIndex(isCurrentIndex ? -1 : index)
									if (!seen) {
										const clonedNotifications = _.cloneDeep(notifications)
										clonedNotifications[index].seen = moment().toISOString()
										await updateNotification(
											firebaseToken,
											clonedNotifications,
											dispatch,
										)
									}
								}}
								key={index}
							>
								<p className='notifications__list__item__created-date'>
									{moment(notification.createdAt).format('DD-MM-YYYY HH:MM')}
								</p>
								<div className='notifications__list__item__row'>
									<p className='notifications__list__item__row__text'>
										{notification.title}
									</p>
									<IconButton
										onClick={async () => {
											const clonedNotifications = _.cloneDeep(notifications)
											clonedNotifications.splice(index, 1)
											await updateNotification(
												firebaseToken,
												clonedNotifications,
												dispatch,
											)
										}}
									>
										<Delete
											sx={{ fontSize: '2rem', color: DASHBOA_PRIMARY_COLOR }}
										/>
									</IconButton>
								</div>
								{isCurrentIndex ? (
									<div className={'notifications__list__item__details'}>
										<p>{notification.message}</p>
									</div>
								) : null}
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}

export default Notifications
