/**
 *
 *
 * @author Janne Hellsten <janne@dashboa.com>
 *
 * @copyright AI Marketing Oy 2022
 */

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, RouterProps } from '@reach/router'
import Button from '../components/Button'
import CustomModal from '../components/Modal'
import { history } from '../../reducers'

type Props = RouteComponentProps & {
	children: JSX.Element | JSX.Element[]
}

const NewVersionChecker = ({ children }: Props): JSX.Element => {
	const { t } = useTranslation()

	const [showUpdateModal, setShowUpdateModal] = useState(false)

	const buildVersion = document.querySelector(
		'[name=build-version]',
	) as Element & { content: string }

	const { location } = history
	useEffect(() => {
		if (
			process.env.NODE_ENV !== 'development' &&
			buildVersion &&
			buildVersion.content
		) {
			const currentVersion = localStorage.getItem('buildVersion')
			if (!currentVersion) {
				localStorage.setItem('buildVersion', buildVersion.content)
			} else if (currentVersion !== buildVersion.content) {
				setShowUpdateModal(true)
			}
		}
	}, [buildVersion, location])
	const reloadFn = () => {
		localStorage.setItem('buildVersion', buildVersion.content)
		window.location.reload()
	}
	return (
		<>
			<CustomModal
				show={showUpdateModal}
				disabledClickOutside={true}
				modalContainerClassName='update-modal'
				noCloseButton
			>
				<div className='update-modal__content'>
					{t('common.new version available')}
				</div>
				<div className='update-modal__footer'>
					<Button text='OK' onClick={reloadFn} className='reset-button' />
				</div>
			</CustomModal>
			{children}
		</>
	)
}
export default NewVersionChecker
