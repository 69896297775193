import {
	GET_CURRENT_USER,
	GET_CURRENT_USER_SUCCESS,
	GET_CURRENT_USER_FAIL,
	SIGN_IN,
	SIGN_IN_SUCCESS,
	SIGN_IN_FAIL,
	SIGN_UP,
	SIGN_UP_SUCCESS,
	SIGN_UP_FAIL,
	FORGET_PASSWORD,
	FORGET_PASSWORD_SUCCESS,
	FORGET_PASSWORD_FAIL,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	AUTH_WITH_FACEBOOK,
	AUTH_WITH_FACEBOOK_SUCCESS,
	AUTH_WITH_FACEBOOK_FAIL,
	AUTH_WITH_GOOGLE,
	AUTH_WITH_GOOGLE_SUCCESS,
	AUTH_WITH_GOOGLE_FAIL,
	GET_GOOGLE_ANALYTIC_VIEWS,
	POST_GOOGLE_ANALYTIC_VIEWS,
	GET_GOOGLE_ADS_DATA,
	GET_GOOGLE_ADS_VIEWS,
	POST_GOOGLE_ADS_VIEWS,
	GET_GOOGLE_SEARCH_CONSOLE_SITES,
	POST_GOOGLE_SEARCH_CONSOLE_SITES,
	GET_FACEBOOK_PAGE_SITES,
	POST_FACEBOOK_PAGE_SITES,
	UPDATE_USER,
	UPDATE_SUBSCRIBER_PROJECT,
	GET_GOOGLE_ANALYTIC_PAGE,
	UNSUBSCRIBE_DATA_SOURCE,
	GET_SUGGESTION_DATA_BY_ID,
	RESET_LOADERS,
	GENERATE_SUGGESTION_BY_TYPE,
	CREATE_PROJECT,
	MODIFY_PROJECT,
	SAVE_NEW_KEYWORD,
	POST_KEYWORD_DATA,
	POST_KEYWORD_RANKING,
	GET_KEYWORD_RANKING,
	SAVE_SITE_URL,
	VIEW_BROKEN_LINKS,
	VIEW_PAGE_DETAILS,
	VIEW_RESOURCES,
	VIEW_BROKEN_LINKS_SUCCESS,
	VIEW_PAGE_DETAILS_SUCCESS,
	VIEW_RESOURCES_SUCCESS,
	SAVE_SITE_URL_SUCCESS,
	CREATE_PROJECT_FAIL,
	CREATE_PROJECT_SUCCESS,
	GENERATE_SUGGESTION_BY_TYPE_FAIL,
	GENERATE_SUGGESTION_BY_TYPE_SUCCESS,
	GET_FACEBOOK_PAGE_SITES_FAIL,
	GET_FACEBOOK_PAGE_SITES_SUCCESS,
	GET_GOOGLE_ADS_DATA_FAIL,
	GET_GOOGLE_ADS_DATA_SUCCESS,
	GET_GOOGLE_ADS_VIEWS_FAIL,
	GET_GOOGLE_ADS_VIEWS_SUCCESS,
	GET_GOOGLE_ANALYTIC_PAGE_FAIL,
	GET_GOOGLE_ANALYTIC_PAGE_SUCCESS,
	GET_GOOGLE_ANALYTIC_VIEWS_FAIL,
	GET_GOOGLE_ANALYTIC_VIEWS_SUCCESS,
	GET_GOOGLE_SEARCH_CONSOLE_SITES_FAIL,
	GET_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS,
	GET_KEYWORD_RANKING_FAIL,
	GET_KEYWORD_RANKING_SUCCESS,
	GET_SUGGESTION_DATA_BY_ID_FAIL,
	GET_SUGGESTION_DATA_BY_ID_SUCCESS,
	MODIFY_PROJECT_FAIL,
	MODIFY_PROJECT_SUCCESS,
	POST_FACEBOOK_PAGE_SITES_FAIL,
	POST_FACEBOOK_PAGE_SITES_SUCCESS,
	POST_GOOGLE_ADS_VIEWS_FAIL,
	POST_GOOGLE_ADS_VIEWS_SUCCESS,
	POST_GOOGLE_ANALYTIC_VIEWS_FAIL,
	POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS,
	POST_GOOGLE_SEARCH_CONSOLE_SITES_FAIL,
	POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS,
	POST_KEYWORD_DATA_FAIL,
	POST_KEYWORD_DATA_SUCCESS,
	POST_KEYWORD_RANKING_FAIL,
	POST_KEYWORD_RANKING_SUCCESS,
	SAVE_NEW_KEYWORD_FAIL,
	SAVE_NEW_KEYWORD_SUCCESS,
	SAVE_SITE_URL_FAIL,
	UNSUBSCRIBE_DATA_SOURCE_FAIL,
	UNSUBSCRIBE_DATA_SOURCE_SUCCESS,
	UPDATE_SUBSCRIBER_PROJECT_FAIL,
	UPDATE_SUBSCRIBER_PROJECT_SUCCESS,
	VIEW_BROKEN_LINKS_FAIL,
	VIEW_PAGE_DETAILS_FAIL,
	VIEW_RESOURCES_FAIL,
	GET_COUNTRIES,
	GET_COUNTRIES_SUCCESS,
	GET_COUNTRIES_FAIL,
	GET_PAYMENT_PLANS,
	GET_PAYMENT_PLANS_SUCCESS,
	GET_PAYMENT_PLANS_FAIL,
	GET_GOOGLE_ANALYTICS_GOALS,
	POST_GOOGLE_ANALYTICS_GOALS,
	GET_GOOGLE_ANALYTICS_GOALS_FAIL,
	POST_GOOGLE_ANALYTICS_GOALS_SUCCESS,
	GET_GOOGLE_ANALYTICS_GOALS_SUCCESS,
	POST_GOOGLE_ANALYTICS_GOALS_FAIL,
	UPDATE_USER_FAIL,
	GET_AUDITS_BY_PROJECT_ID_FAIL,
	UPDATE_USER_SUCCESS,
	GET_AI_CONTENT,
	UPDATE_SUGGESTION_STATUS_SUCCESS,
	UPDATE_SUGGESTION_STATUS_FAIL,
	GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_FAIL,
	GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS,
	GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID,
	GET_AI_HEADLINES_FAIL,
	GET_AI_HEADLINES_SUCCESS,
	GET_AI_HEADLINES,
	UPDATE_DATA_SOURCE_FAIL,
	START_SOME_POST_SUGGESTIONS_LOADING,
	STOP_SOME_POST_SUGGESTIONS_LOADING,
	POST_EXTERNAL_LINKS_FOR_SUGGESTION,
	POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS,
	GET_WP_ITEM_BY_ID_SUCCESS,
	GET_WP_ITEM_BY_ID,
	GET_WP_ITEM_BY_ID_FAIL,
	GET_RANKED_KEYWORDS_SUCCESS,
	GET_RANKED_KEYWORDS_FAIL,
	GET_RANKED_KEYWORDS,
	UPDATE_WP_ITEM_BY_ID,
	UPDATE_WP_ITEM_BY_ID_FAIL,
	UPDATE_WP_ITEM_BY_ID_SUCCESS,
	CREATE_WP_ITEM_BY_TYPE_FAIL,
	CREATE_WP_ITEM_BY_TYPE_SUCCESS,
	CREATE_WP_ITEM_BY_TYPE,
	UPDATE_TASK_FAIL,
	UPDATE_TASK_SUCCESS,
	UPDATE_TASK,
	GET_GOOGLE_ANALYTICS_EVENTS,
	GET_GOOGLE_ANALYTICS_EVENTS_FAIL,
	GET_GOOGLE_ANALYTICS_EVENTS_SUCCESS,
	CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS,
	CREATE_COMMENT_BY_SUGGESTION_ID_FAIL,
	CREATE_COMMENT_BY_TASK_ID_FAIL,
	CREATE_COMMENT_BY_TASK_ID_SUCCESS,
	CREATE_COMMENT_BY_SUGGESTION_ID,
	CREATE_COMMENT_BY_TASK_ID,
	INVITE_USER_TO_COMPANY,
	INVITE_USER_TO_COMPANY_FAIL,
	INVITE_USER_TO_COMPANY_SUCCESS,
	UPDATE_COMPANY_FAIL,
	UPDATE_COMPANY_SUCCESS,
	UPDATE_COMPANY_USER_FAIL,
	UPDATE_COMPANY_USER_SUCCESS,
	UPDATE_COMPANY,
	UPDATE_COMPANY_USER,
	STOP_APP_LOADING,
	START_APP_LOADING,
	SET_APP_LOADING_MESSAGE,
	GENERATE_TRACKING_DATA,
	GENERATE_TRACKING_DATA_SUCCESS,
	GENERATE_TRACKING_DATA_FAIL,
	GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_SUCCESS,
	GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_FAIL,
	GET_GOOGLE_ANALYTIC_GA4_PROPERTIES,
	DELETE_FACEBOOK_POST,
	DELETE_FACEBOOK_POST_SUCCESS,
	DELETE_FACEBOOK_POST_FAIL,
	DELETE_INSTAGRAM_SCHEDULED_POST,
	DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS,
	DELETE_INSTAGRAM_SCHEDULED_POST_FAIL,
} from '../actions/types'

import * as allActionTypes from '../actions/types'

type LoadingStateType = {
	authLoading: boolean
	appLoading: boolean
	message: string
	somePostSuggestionsLoading: boolean
}

const loadingInitialState: LoadingStateType = {
	authLoading: false,
	appLoading: false,
	message: 'Loading',
	somePostSuggestionsLoading: false,
}

export const stopLoading = (): any => {
	return { type: allActionTypes.STOP_APP_LOADING }
}
export const startLoading = (): any => {
	return { type: allActionTypes.START_APP_LOADING }
}
export const startSomePostSuggestionsLoading = (): any => {
	return { type: allActionTypes.START_SOME_POST_SUGGESTIONS_LOADING }
}
export const stopSomePostSuggestionsLoading = (): any => {
	return { type: allActionTypes.STOP_SOME_POST_SUGGESTIONS_LOADING }
}
export const setLoadingMessage = (message: string): any => {
	return { type: allActionTypes.SET_APP_LOADING_MESSAGE, payload: message }
}

export const loadingReducer = (
	state = loadingInitialState,
	action: { type: keyof typeof allActionTypes; payload: any },
): LoadingStateType => {
	switch (action.type) {
		case allActionTypes.START_SOME_POST_SUGGESTIONS_LOADING:
			return { ...state, somePostSuggestionsLoading: true }
		case allActionTypes.STOP_SOME_POST_SUGGESTIONS_LOADING:
			return { ...state, somePostSuggestionsLoading: false }
		case allActionTypes.SET_APP_LOADING_MESSAGE:
			return { ...state, message: action.payload }
		case allActionTypes.SIGN_UP:
		case allActionTypes.SIGN_IN:
		case allActionTypes.GET_CURRENT_USER:
		case allActionTypes.FORGET_PASSWORD:
		case allActionTypes.RESET_PASSWORD:
		case allActionTypes.AUTH_WITH_FACEBOOK:
		case allActionTypes.AUTH_WITH_GOOGLE:
			return { ...state, authLoading: true }
		case allActionTypes.SIGN_UP_FAIL:
		case allActionTypes.SIGN_IN_FAIL:
		case allActionTypes.GET_CURRENT_USER_FAIL:
		case allActionTypes.FORGET_PASSWORD_FAIL:
		case allActionTypes.RESET_PASSWORD_FAIL:
		case allActionTypes.AUTH_WITH_FACEBOOK_FAIL:
		case allActionTypes.AUTH_WITH_GOOGLE_FAIL:
		case allActionTypes.SIGN_UP_SUCCESS:
		case allActionTypes.SIGN_IN_SUCCESS:
		case allActionTypes.GET_CURRENT_USER_SUCCESS:
		case allActionTypes.FORGET_PASSWORD_SUCCESS:
		case allActionTypes.RESET_PASSWORD_SUCCESS:
		case allActionTypes.AUTH_WITH_FACEBOOK_SUCCESS:
		case allActionTypes.AUTH_WITH_GOOGLE_SUCCESS:
			return {
				...state,
				authLoading: false,
				message: loadingInitialState.message,
			}
		case allActionTypes.GET_SUGGESTION_DATA_BY_ID:
		case allActionTypes.GENERATE_TRACKING_DATA:
			return {
				...state,
				appLoading: true,
				authLoading: false,
				message: 'Loading suggestion details data',
			}
		case allActionTypes.GENERATE_TASK_TRACKING_DATA:
			return {
				...state,
				appLoading: true,
				authLoading: false,
				message: 'Loading task details data',
			}
		case allActionTypes.CREATE_PROJECT:
		case allActionTypes.GET_AI_CONTENT:
			return {
				...state,
				appLoading: true,
				authLoading: false,
			}
		case GENERATE_SUGGESTION_BY_TYPE:
		case GET_GOOGLE_ANALYTIC_GA4_PROPERTIES:
		case GET_GOOGLE_ANALYTIC_VIEWS:
		case POST_GOOGLE_ANALYTIC_VIEWS:
		case POST_KEYWORD_DATA:
		case POST_KEYWORD_RANKING:
		case GET_GOOGLE_ANALYTIC_PAGE:
		case GET_GOOGLE_ADS_DATA:
		case GET_GOOGLE_ADS_VIEWS:
		case POST_GOOGLE_ADS_VIEWS:
		case GET_GOOGLE_ANALYTICS_GOALS:
		case POST_GOOGLE_ANALYTICS_GOALS:
		case GET_COUNTRIES:
		case SAVE_SITE_URL:
		case GET_KEYWORD_RANKING:
		case allActionTypes.GET_ALL_PRODUCT_PRICE:
		case SAVE_NEW_KEYWORD:
		case GET_RANKED_KEYWORDS:
		case VIEW_BROKEN_LINKS:
		case VIEW_PAGE_DETAILS:
		case VIEW_RESOURCES:
		case GET_GOOGLE_SEARCH_CONSOLE_SITES:
		case POST_GOOGLE_SEARCH_CONSOLE_SITES:
		case DELETE_FACEBOOK_POST:
		case DELETE_INSTAGRAM_SCHEDULED_POST:
		case MODIFY_PROJECT:
		case GET_FACEBOOK_PAGE_SITES:
		case POST_FACEBOOK_PAGE_SITES:
		case UPDATE_SUBSCRIBER_PROJECT:
		case UNSUBSCRIBE_DATA_SOURCE:
		case GET_PAYMENT_PLANS:
		case START_APP_LOADING:
		case allActionTypes.GET_PRODUCT_CATEGORY_TREE_MAP:
		case GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID:
		case POST_EXTERNAL_LINKS_FOR_SUGGESTION:
		case GET_GOOGLE_ANALYTICS_EVENTS:
		case GET_AI_HEADLINES:
		case GET_WP_ITEM_BY_ID:
		case UPDATE_WP_ITEM_BY_ID:
		case CREATE_WP_ITEM_BY_TYPE:
		case UPDATE_TASK:
		case CREATE_COMMENT_BY_SUGGESTION_ID:
		case CREATE_COMMENT_BY_TASK_ID:
		case INVITE_USER_TO_COMPANY:
		case UPDATE_COMPANY:
		case UPDATE_COMPANY_USER:
		case allActionTypes.LIST_ALL_FINETUNE_FILES:
		case allActionTypes.CREATE_FINETUNE_MODEL:
		case allActionTypes.GENERATE_SUGGESTION_BY_TYPE:
		case allActionTypes.GET_GOOGLE_ANALYTIC_GA4_PROPERTIES:
		case allActionTypes.GET_GOOGLE_ANALYTIC_VIEWS:
		case allActionTypes.POST_GOOGLE_ANALYTIC_VIEWS:
		case allActionTypes.POST_KEYWORD_DATA:
		case allActionTypes.POST_KEYWORD_RANKING:
		case allActionTypes.GET_GOOGLE_ANALYTIC_PAGE:
		case allActionTypes.GET_GOOGLE_ADS_DATA:
		case allActionTypes.GET_GOOGLE_ADS_VIEWS:
		case allActionTypes.POST_GOOGLE_ADS_VIEWS:
		case allActionTypes.GET_GOOGLE_ANALYTICS_GOALS:
		case allActionTypes.POST_GOOGLE_ANALYTICS_GOALS:
		case allActionTypes.GET_COUNTRIES:
		case allActionTypes.SAVE_SITE_URL:
		case allActionTypes.GET_KEYWORD_RANKING:
		case allActionTypes.SAVE_NEW_KEYWORD:
		case allActionTypes.GET_RANKED_KEYWORDS:
		case allActionTypes.VIEW_BROKEN_LINKS:
		case allActionTypes.VIEW_PAGE_DETAILS:
		case allActionTypes.VIEW_RESOURCES:
		case allActionTypes.GET_GOOGLE_SEARCH_CONSOLE_SITES:
		case allActionTypes.POST_GOOGLE_SEARCH_CONSOLE_SITES:
		case allActionTypes.DELETE_FACEBOOK_POST:
		case allActionTypes.DELETE_INSTAGRAM_SCHEDULED_POST:
		case allActionTypes.MODIFY_PROJECT:
		case allActionTypes.GET_FACEBOOK_PAGE_SITES:
		case allActionTypes.POST_FACEBOOK_PAGE_SITES:
		case allActionTypes.UPDATE_SUBSCRIBER_PROJECT:
		case allActionTypes.UNSUBSCRIBE_DATA_SOURCE:
		case allActionTypes.GET_PAYMENT_PLANS:
		case allActionTypes.START_APP_LOADING:
		case allActionTypes.GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID:
		case allActionTypes.POST_EXTERNAL_LINKS_FOR_SUGGESTION:
		case allActionTypes.GET_GOOGLE_ANALYTICS_EVENTS:
		case allActionTypes.GET_AI_HEADLINES:
		case allActionTypes.GENERATE_BLOG_CONTENT:
		case allActionTypes.GENERATE_SUBHEADINGS:
		case allActionTypes.UPDATE_TASK:
		case allActionTypes.CREATE_COMMENT_BY_SUGGESTION_ID:
		case allActionTypes.CREATE_COMMENT_BY_TASK_ID:
		case allActionTypes.INVITE_USER_TO_COMPANY:
		case allActionTypes.INVITE_CONTRIBUTOR_TO_PROJECT:
		case allActionTypes.UPDATE_CONTRIBUTOR_DATA:
		case allActionTypes.DELETE_CONTRIBUTOR_DATA:
		case allActionTypes.UPDATE_COMPANY:
		case allActionTypes.UPDATE_COMPANY_USER:
		case allActionTypes.GET_RANK_PREDICTIONS_BY_COMPANY_ID:
		case allActionTypes.GET_RANK_PREDICTION_BY_ID:
		case allActionTypes.CREATE_NEW_RANK_PREDICTION:
		case allActionTypes.CHECK_RANK_PREDICTION_BY_ID:
		case allActionTypes.GET_GOOGLE_ADS_CHANGE_EVENTS:
		case allActionTypes.SAVE_KEYWORD_LABEL:
		case allActionTypes.UPDATE_KEYWORD_LABEL_BY_ID:
		case allActionTypes.SAVE_CUSTOM_REPORT_TEMPLATE:
		case allActionTypes.UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE:
		case allActionTypes.GET_REPORT_COMPONENT_DATA:
		case allActionTypes.GET_REPORT_DATA:
		case allActionTypes.GET_GOOGLE_ANALYTICS_COMPARISON_DATA:
		case allActionTypes.GET_PRODUCT_REFERENCE:
		case allActionTypes.SAVE_PRODUCT_REFERENCE:
			return {
				...state,
				appLoading: true,
				authLoading: false,
			}
		case allActionTypes.STOP_APP_LOADING:
			return {
				...state,
				appLoading: false,
				authLoading: false,
				message: loadingInitialState.message,
			}
		case allActionTypes.UPDATE_USER:
			return { ...state, appLoading: true }
		case GENERATE_SUGGESTION_BY_TYPE_SUCCESS:
		case GET_SUGGESTION_DATA_BY_ID_SUCCESS:
		case GET_GOOGLE_ANALYTIC_VIEWS_SUCCESS:
		case POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS:
		case POST_KEYWORD_DATA_SUCCESS:
		case POST_KEYWORD_RANKING_SUCCESS:
		case GET_GOOGLE_ANALYTIC_PAGE_SUCCESS:
		case GET_GOOGLE_ADS_DATA_SUCCESS:
		case GET_GOOGLE_ADS_VIEWS_SUCCESS:
		case GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_SUCCESS:
		case POST_GOOGLE_ADS_VIEWS_SUCCESS:
		case CREATE_PROJECT_SUCCESS:
		case SAVE_SITE_URL_SUCCESS:
		case GET_KEYWORD_RANKING_SUCCESS:
		case allActionTypes.GET_PRODUCT_CATEGORY_TREE_MAP_SUCCESS:
		case allActionTypes.GET_ALL_PRODUCT_PRICE_SUCCESS:
		case SAVE_NEW_KEYWORD_SUCCESS:
		case GET_RANKED_KEYWORDS_SUCCESS:
		case VIEW_BROKEN_LINKS_SUCCESS:
		case VIEW_PAGE_DETAILS_SUCCESS:
		case VIEW_RESOURCES_SUCCESS:
		case GET_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS:
		case POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS:
		case MODIFY_PROJECT_SUCCESS:
		case GET_FACEBOOK_PAGE_SITES_SUCCESS:
		case POST_FACEBOOK_PAGE_SITES_SUCCESS:
		case UPDATE_SUBSCRIBER_PROJECT_SUCCESS:
		case UNSUBSCRIBE_DATA_SOURCE_SUCCESS:
		case GENERATE_SUGGESTION_BY_TYPE_FAIL:
		case GET_SUGGESTION_DATA_BY_ID_FAIL:
		case GET_GOOGLE_ANALYTIC_VIEWS_FAIL:
		case POST_GOOGLE_ANALYTIC_VIEWS_FAIL:
		case POST_KEYWORD_DATA_FAIL:
		case POST_KEYWORD_RANKING_FAIL:
		case GET_GOOGLE_ANALYTIC_PAGE_FAIL:
		case GET_GOOGLE_ADS_DATA_FAIL:
		case GET_GOOGLE_ADS_VIEWS_FAIL:
		case POST_GOOGLE_ADS_VIEWS_FAIL:
		case GET_GOOGLE_ANALYTICS_GOALS_FAIL:
		case POST_GOOGLE_ANALYTICS_GOALS_FAIL:
		case GET_GOOGLE_ANALYTICS_EVENTS_FAIL:
		case GET_GOOGLE_ANALYTICS_EVENTS_SUCCESS:
		case GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_FAIL:
		case GET_GOOGLE_ANALYTICS_GOALS_SUCCESS:
		case POST_GOOGLE_ANALYTICS_GOALS_SUCCESS:
		case DELETE_FACEBOOK_POST_SUCCESS:
		case DELETE_FACEBOOK_POST_FAIL:
		case DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS:
		case DELETE_INSTAGRAM_SCHEDULED_POST_FAIL:
		case CREATE_PROJECT_FAIL:
		case SAVE_SITE_URL_FAIL:
		case GET_KEYWORD_RANKING_FAIL:
		case allActionTypes.GET_PRODUCT_CATEGORY_TREE_MAP_FAIL:
		case SAVE_NEW_KEYWORD_FAIL:
		case GET_RANKED_KEYWORDS_FAIL:
		case VIEW_BROKEN_LINKS_FAIL:
		case VIEW_PAGE_DETAILS_FAIL:
		case VIEW_RESOURCES_FAIL:
		case GET_GOOGLE_SEARCH_CONSOLE_SITES_FAIL:
		case POST_GOOGLE_SEARCH_CONSOLE_SITES_FAIL:
		case MODIFY_PROJECT_FAIL:
		case GET_FACEBOOK_PAGE_SITES_FAIL:
		case POST_FACEBOOK_PAGE_SITES_FAIL:
		case UPDATE_SUBSCRIBER_PROJECT_FAIL:
		case GET_PAYMENT_PLANS_SUCCESS:
		case GET_PAYMENT_PLANS_FAIL:
		case GET_COUNTRIES_SUCCESS:
		case GET_COUNTRIES_FAIL:
		case UPDATE_USER_FAIL:
		case UPDATE_USER_SUCCESS:
		case UNSUBSCRIBE_DATA_SOURCE_FAIL:
		case UPDATE_DATA_SOURCE_FAIL:
		case GET_AUDITS_BY_PROJECT_ID_FAIL:
		case UPDATE_SUGGESTION_STATUS_SUCCESS:
		case UPDATE_SUGGESTION_STATUS_FAIL:
		case GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS:
		case GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_FAIL:
		case GET_AI_HEADLINES_FAIL:
		case GET_AI_HEADLINES_SUCCESS:
		case POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS:
		case GET_WP_ITEM_BY_ID_SUCCESS:
		case GET_WP_ITEM_BY_ID_FAIL:
		case UPDATE_WP_ITEM_BY_ID_FAIL:
		case UPDATE_WP_ITEM_BY_ID_SUCCESS:
		case CREATE_WP_ITEM_BY_TYPE_FAIL:
		case CREATE_WP_ITEM_BY_TYPE_SUCCESS:
		case UPDATE_TASK_FAIL:
		case UPDATE_TASK_SUCCESS:
		case CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS:
		case CREATE_COMMENT_BY_SUGGESTION_ID_FAIL:
		case CREATE_COMMENT_BY_TASK_ID_FAIL:
		case CREATE_COMMENT_BY_TASK_ID_SUCCESS:
		case INVITE_USER_TO_COMPANY_FAIL:
		case INVITE_USER_TO_COMPANY_SUCCESS:
		case UPDATE_COMPANY_FAIL:
		case UPDATE_COMPANY_SUCCESS:
		case UPDATE_COMPANY_USER_FAIL:
		case UPDATE_COMPANY_USER_SUCCESS:
		case GENERATE_TRACKING_DATA_SUCCESS:
		case GENERATE_TRACKING_DATA_FAIL:
		case allActionTypes.LIST_ALL_FINETUNE_FILES_FAIL:
		case allActionTypes.GET_KEYWORD_RANKING_FOR_CHECKING_SUCCESS:
		case allActionTypes.GET_KEYWORD_RANKING_FOR_CHECKING_FAIL:
		case allActionTypes.GENERATE_SUGGESTION_BY_TYPE_SUCCESS:
		case allActionTypes.CREATE_FINETUNE_MODEL_SUCCESS:
		case allActionTypes.SAVE_CUSTOM_REPORT_TEMPLATE_SUCCESS:
		case allActionTypes.SAVE_CUSTOM_REPORT_TEMPLATE_FAIL:
		case allActionTypes.GET_SUGGESTION_DATA_BY_ID_SUCCESS:
		case allActionTypes.GET_GOOGLE_ANALYTIC_VIEWS_SUCCESS:
		case allActionTypes.POST_GOOGLE_ANALYTIC_VIEWS_SUCCESS:
		case allActionTypes.POST_KEYWORD_DATA_SUCCESS:
		case allActionTypes.POST_KEYWORD_RANKING_SUCCESS:
		case allActionTypes.GET_GOOGLE_ANALYTIC_PAGE_SUCCESS:
		case allActionTypes.GET_GOOGLE_ADS_DATA_SUCCESS:
		case allActionTypes.GET_GOOGLE_ADS_VIEWS_SUCCESS:
		case allActionTypes.GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_SUCCESS:
		case allActionTypes.POST_GOOGLE_ADS_VIEWS_SUCCESS:
		case allActionTypes.CREATE_PROJECT_SUCCESS:
		case allActionTypes.SAVE_SITE_URL_SUCCESS:
		case allActionTypes.GET_KEYWORD_RANKING_SUCCESS:
		case allActionTypes.SAVE_NEW_KEYWORD_SUCCESS:
		case allActionTypes.GET_RANKED_KEYWORDS_SUCCESS:
		case allActionTypes.VIEW_BROKEN_LINKS_SUCCESS:
		case allActionTypes.VIEW_PAGE_DETAILS_SUCCESS:
		case allActionTypes.VIEW_RESOURCES_SUCCESS:
		case allActionTypes.GET_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS:
		case allActionTypes.POST_GOOGLE_SEARCH_CONSOLE_SITES_SUCCESS:
		case allActionTypes.MODIFY_PROJECT_SUCCESS:
		case allActionTypes.GET_FACEBOOK_PAGE_SITES_SUCCESS:
		case allActionTypes.POST_FACEBOOK_PAGE_SITES_SUCCESS:
		case allActionTypes.UPDATE_SUBSCRIBER_PROJECT_SUCCESS:
		case allActionTypes.UNSUBSCRIBE_DATA_SOURCE_SUCCESS:
		case allActionTypes.GENERATE_SUGGESTION_BY_TYPE_FAIL:
		case allActionTypes.CREATE_FINETUNE_MODEL_FAIL:
		case allActionTypes.GET_SUGGESTION_DATA_BY_ID_FAIL:
		case allActionTypes.GET_GOOGLE_ANALYTIC_VIEWS_FAIL:
		case allActionTypes.POST_GOOGLE_ANALYTIC_VIEWS_FAIL:
		case allActionTypes.POST_KEYWORD_DATA_FAIL:
		case allActionTypes.POST_KEYWORD_RANKING_FAIL:
		case allActionTypes.GET_GOOGLE_ANALYTIC_PAGE_FAIL:
		case allActionTypes.GET_GOOGLE_ADS_DATA_FAIL:
		case allActionTypes.GET_GOOGLE_ADS_VIEWS_FAIL:
		case allActionTypes.POST_GOOGLE_ADS_VIEWS_FAIL:
		case allActionTypes.GET_GOOGLE_ANALYTICS_GOALS_FAIL:
		case allActionTypes.POST_GOOGLE_ANALYTICS_GOALS_FAIL:
		case allActionTypes.GET_GOOGLE_ANALYTICS_EVENTS_FAIL:
		case allActionTypes.GET_GOOGLE_ANALYTICS_EVENTS_SUCCESS:
		case allActionTypes.GET_GOOGLE_ANALYTIC_GA4_PROPERTIES_FAIL:
		case allActionTypes.GET_GOOGLE_ANALYTICS_GOALS_SUCCESS:
		case allActionTypes.POST_GOOGLE_ANALYTICS_GOALS_SUCCESS:
		case allActionTypes.DELETE_FACEBOOK_POST_SUCCESS:
		case allActionTypes.DELETE_FACEBOOK_POST_FAIL:
		case allActionTypes.DELETE_INSTAGRAM_SCHEDULED_POST_SUCCESS:
		case allActionTypes.DELETE_INSTAGRAM_SCHEDULED_POST_FAIL:
		case allActionTypes.CREATE_PROJECT_FAIL:
		case allActionTypes.SAVE_SITE_URL_FAIL:
		case allActionTypes.GET_KEYWORD_RANKING_FAIL:
		case allActionTypes.SAVE_NEW_KEYWORD_FAIL:
		case allActionTypes.GET_RANKED_KEYWORDS_FAIL:
		case allActionTypes.VIEW_BROKEN_LINKS_FAIL:
		case allActionTypes.VIEW_PAGE_DETAILS_FAIL:
		case allActionTypes.VIEW_RESOURCES_FAIL:
		case allActionTypes.GET_GOOGLE_SEARCH_CONSOLE_SITES_FAIL:
		case allActionTypes.POST_GOOGLE_SEARCH_CONSOLE_SITES_FAIL:
		case allActionTypes.MODIFY_PROJECT_FAIL:
		case allActionTypes.GET_FACEBOOK_PAGE_SITES_FAIL:
		case allActionTypes.POST_FACEBOOK_PAGE_SITES_FAIL:
		case allActionTypes.UPDATE_SUBSCRIBER_PROJECT_FAIL:
		case allActionTypes.GET_PAYMENT_PLANS_SUCCESS:
		case allActionTypes.GET_PAYMENT_PLANS_FAIL:
		case allActionTypes.GET_COUNTRIES_SUCCESS:
		case allActionTypes.GET_COUNTRIES_FAIL:
		case allActionTypes.UPDATE_USER_FAIL:
		case allActionTypes.UPDATE_USER_SUCCESS:
		case allActionTypes.UNSUBSCRIBE_DATA_SOURCE_FAIL:
		case allActionTypes.UPDATE_DATA_SOURCE_FAIL:
		case allActionTypes.GET_AUDITS_BY_PROJECT_ID_FAIL:
		case allActionTypes.UPDATE_SUGGESTION_STATUS_SUCCESS:
		case allActionTypes.UPDATE_SUGGESTION_STATUS_FAIL:
		case allActionTypes.GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS:
		case allActionTypes.GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_FAIL:
		case allActionTypes.GET_AI_HEADLINES_FAIL:
		case allActionTypes.GET_AI_HEADLINES_SUCCESS:
		case allActionTypes.GENERATE_SUBHEADINGS_FAIL:
		case allActionTypes.GENERATE_SUBHEADINGS_SUCCESS:
		case allActionTypes.POST_EXTERNAL_LINKS_FOR_SUGGESTION_SUCCESS:
		case allActionTypes.UPDATE_TASK_FAIL:
		case allActionTypes.UPDATE_TASK_SUCCESS:
		case allActionTypes.CREATE_COMMENT_BY_SUGGESTION_ID_SUCCESS:
		case allActionTypes.CREATE_COMMENT_BY_SUGGESTION_ID_FAIL:
		case allActionTypes.CREATE_COMMENT_BY_TASK_ID_FAIL:
		case allActionTypes.CREATE_COMMENT_BY_TASK_ID_SUCCESS:
		case allActionTypes.INVITE_USER_TO_COMPANY_FAIL:
		case allActionTypes.INVITE_USER_TO_COMPANY_SUCCESS:
		case allActionTypes.INVITE_CONTRIBUTOR_TO_PROJECT_FAIL:
		case allActionTypes.INVITE_CONTRIBUTOR_TO_PROJECT_SUCCESS:
		case allActionTypes.UPDATE_CONTRIBUTOR_DATA_FAIL:
		case allActionTypes.UPDATE_CONTRIBUTOR_DATA_SUCCESS:
		case allActionTypes.DELETE_CONTRIBUTOR_DATA_FAIL:
		case allActionTypes.DELETE_CONTRIBUTOR_DATA_SUCCESS:
		case allActionTypes.UPDATE_COMPANY_FAIL:
		case allActionTypes.UPDATE_COMPANY_SUCCESS:
		case allActionTypes.UPDATE_COMPANY_USER_FAIL:
		case allActionTypes.UPDATE_COMPANY_USER_SUCCESS:
		case allActionTypes.GENERATE_TRACKING_DATA_SUCCESS:
		case allActionTypes.GENERATE_TRACKING_DATA_FAIL:
		case allActionTypes.GENERATE_TASK_TRACKING_DATA_SUCCESS:
		case allActionTypes.GENERATE_TASK_TRACKING_DATA_FAIL:
		case allActionTypes.GET_RANK_PREDICTIONS_BY_COMPANY_ID_FAIL:
		case allActionTypes.GET_RANK_PREDICTIONS_BY_COMPANY_ID_SUCCESS:
		case allActionTypes.GET_RANK_PREDICTION_BY_ID_FAIL:
		case allActionTypes.GET_RANK_PREDICTION_BY_ID_SUCCESS:
		case allActionTypes.CREATE_NEW_RANK_PREDICTION_FAIL:
		case allActionTypes.CREATE_NEW_RANK_PREDICTION_SUCCESS:
		case allActionTypes.CHECK_RANK_PREDICTION_BY_ID_FAIL:
		case allActionTypes.CHECK_RANK_PREDICTION_BY_ID_SUCCESS:
		case allActionTypes.GET_GOOGLE_ADS_CHANGE_EVENTS_FAIL:
		case allActionTypes.GET_GOOGLE_ADS_CHANGE_EVENTS_SUCCESS:
		case allActionTypes.GET_GOOGLE_ADS_CAMPAIGNS_FAIL:
		case allActionTypes.GET_GOOGLE_ADS_CAMPAIGNS_SUCCESS:
		case allActionTypes.SAVE_KEYWORD_LABEL_SUCCESS:
		case allActionTypes.UPDATE_KEYWORD_LABEL_BY_ID_SUCCESS:
		case allActionTypes.UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE_FAIL:
		case allActionTypes.UPDATE_SAVED_CUSTOM_REPORT_TEMPLATE_SUCCESS:
		case allActionTypes.GET_REPORT_COMPONENT_DATA_FAIL:
		case allActionTypes.GET_REPORT_COMPONENT_DATA_SUCCESS:
		case allActionTypes.GET_REPORT_DATA_FAIL:
		case allActionTypes.GET_REPORT_DATA_SUCCESS:
		case allActionTypes.GET_GOOGLE_ANALYTICS_COMPARISON_DATA_SUCCESS:
		case allActionTypes.GET_GOOGLE_ANALYTICS_COMPARISON_DATA_FAIL:
		case allActionTypes.LIST_ALL_FINETUNE_FILES_SUCCESS:
		case allActionTypes.GET_PRODUCT_REFERENCE_SUCCESS:
		case allActionTypes.SAVE_PRODUCT_REFERENCE_SUCCESS:
			return {
				...state,
				appLoading: false,
				message: loadingInitialState.message,
			}
		case allActionTypes.RESET_LOADERS:
			return loadingInitialState
		default:
			return state
	}
}
export const loadingSelectors = {
	getAuthLoading: (
		state = loadingInitialState,
	): LoadingStateType['authLoading'] => state.authLoading,
	getAppLoading: (
		state = loadingInitialState,
	): LoadingStateType['appLoading'] => state.appLoading,
	getPostSuggestionsLoading: (
		state = loadingInitialState,
	): LoadingStateType['somePostSuggestionsLoading'] =>
		state.somePostSuggestionsLoading,
}
