import React, { useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import AddIcon from '@material-ui/icons/Add'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BuildIcon from '@material-ui/icons/Build'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PaymentIcon from '@material-ui/icons/Payment'
import _ from 'lodash'
import Collapse from '@material-ui/core/Collapse'
import {
	analyse,
	content_creation,
	CountryObjSelectValue,
	EHIGHLIGHT,
	ETUTORIAL_TYPES,
	highlighter,
	IProject,
	IRouteObj,
	manage,
	ROUTER_PATH,
	settings,
	tools,
} from '../../constants'

import {
	dashboardSelectors,
	history,
	loadingSelectors,
	projectSelectors,
	taskSelectors,
	userSelectors,
} from '../../reducers'
import { connect } from 'react-redux'
import {
	projectActions,
	taskActions,
	tutorialActions,
	userActions,
} from '../../actions'
import {
	ChevronRightRounded,
	ExpandLess,
	ExpandMore,
	PowerSettingsNew,
	Settings,
} from '@material-ui/icons'
import CreateIcon from '@mui/icons-material/Create'
import BusinessIcon from '@mui/icons-material/Business'
import { Avatar, Divider, IconButton } from '@material-ui/core'
import SelectWithSearch from './SelectWithSearch'
import Modal from './Modal'
import Button from './Button'
import Createproject from './CreateProject'
import {
	transformProjectToOptions,
	transformProjectsToOptions,
} from '../../helpers/dataTransformer'
import { MenuItem } from './MenuItem'
import { MenuItemContainer } from './MenuItemContainer'
import logo from '../../assets/images/logo_light_blue_for_website.png'
import smallLogo from '../../assets/images/logo_svg_turqoise.svg'
import { tutorialSelectors, TutorialState } from '../../reducers/tutorial'
import { useTranslation } from 'react-i18next'
import { startLoading, stopLoading } from '../../reducers/loading'
import ToolTip from './ToolTip'
import { PrivacyPolicyLink } from './PrivacyPolicyLink'
import { Company, User } from '../../reducers/user'
import { Suggestion } from '../../reducers/dashboard'
import { getUserFirebaseToken } from '../../actions/user'
import { useLocation } from 'react-router-dom'

type SideNavProps = {
	user: User
	firebaseToken: string | null
	company: Company
	allProjects: Pick<IProject, 'id' | 'name' | 'company'>[]
	getProjectById: (projectId: number) => any
	getProjectUser: (projectId: number, companyId: number) => void
	currentProject: IProject
	logout: () => void
	allSuggestions: {
		[key: number]: Suggestion[]
	}
	getSuggestionsAll: (projectId: number) => void
	resetTasks: () => void
	activeSuggestions: Suggestion[]
	pendingSuggestions: Suggestion[]
	getAllTasks: (projectId: number) => void
	activeTasks: taskActions.TaskItem[]
	pendingTasks: taskActions.TaskItem[]
	tutorial: TutorialState
	changeTutorialField: (payload: Partial<TutorialState>) => void
	allCountries: CountryObjSelectValue[]
	getCountries: () => void
	checkProjectAmount: () => void
	getFirebaseToken: () => void
	currentProjectAmounts: {
		max: number
		numberOfActiveProject: number
		numberOfProjectCreated: number
	}
	isAuthenticated: boolean
	setLoadingStart: () => void
	setLoadingEnd: () => void
	changeSidenavState: () => void
	setSidenavState: (isExpanded: boolean) => void
	isSidenavOpen: boolean
	resetProject: () => void
}

const SideNav = ({
	user,
	firebaseToken,
	company,
	allProjects,
	getProjectById,
	getProjectUser,
	currentProject,
	logout,
	allSuggestions,
	getSuggestionsAll,
	resetTasks,
	activeSuggestions,
	pendingSuggestions,
	getAllTasks,
	activeTasks,
	pendingTasks,
	tutorial,
	changeTutorialField,
	allCountries,
	getCountries,
	checkProjectAmount,
	getFirebaseToken,
	currentProjectAmounts,
	isAuthenticated,
	setLoadingStart,
	setLoadingEnd,
	changeSidenavState,
	setSidenavState,
	isSidenavOpen,
	resetProject,
}: SideNavProps) => {
	const location = useLocation()
	const currentPath = location.pathname
	const [showingModal, setShowingModal] = useState(false)
	const [toolsOpen, setToolsOpen] = useState(
		currentPath.includes('tools') || false,
	)
	const [contentCreatorOpen, setContentCreatorOpen] = useState(
		currentPath.includes('content-tools') || false,
	)
	const { t } = useTranslation()

	useEffect(() => {
		if (isAuthenticated) {
			checkProjectAmount()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company.maximumNumberOfProject])

	useEffect(() => {
		const getTasks = async () => {
			await Promise.all([
				getSuggestionsAll(currentProject.id),
				getAllTasks(currentProject.id),
			])
		}
		if (currentProject.id > 0) {
			getTasks()
		}
	}, [currentProject])
	useEffect(() => {
		if (isAuthenticated && !firebaseToken && user.firebaseId) {
			getFirebaseToken()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.firebaseId, firebaseToken])

	useEffect(() => {
		if (
			!tutorial.firstTime &&
			currentProjectAmounts.numberOfActiveProject === 1 &&
			currentPath === ROUTER_PATH.OVERVIEW
		) {
			setTimeout(() => {
				history.push(ROUTER_PATH.OVERVIEW)
				setSidenavState(true)
				changeTutorialField({
					currentStep: 0,
					tutorialType: ETUTORIAL_TYPES.FIRST_TIME_USER,
					modalOpen: true,
					nextStepValid: true,
					maxSteps: 13,
				})
			}, 2000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProjectAmounts.numberOfActiveProject])

	useEffect(() => {
		if (
			!allProjects.length &&
			!currentPath.includes(ROUTER_PATH.PROJECT_CREATION_WIZARD)
		) {
			if (
				currentPath.includes(ROUTER_PATH.SUBSCRIPTION) ||
				currentPath.includes(ROUTER_PATH.PROFILE) ||
				currentPath.includes(ROUTER_PATH.COMPANY)
			) {
				return
			} else {
				setSidenavState(false)
				history.push(`${ROUTER_PATH.PROJECT_CREATION_WIZARD}0`)
			}
		}
		if (allCountries.length === 0) {
			const getAllCoutries = async () => {
				try {
					await getCountries()
				} catch (error) {
					console.error(error)
				}
			}
			getAllCoutries()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProjectAmounts.numberOfActiveProject, currentPath])

	useEffect(() => {
		if (tutorial.highlighted === EHIGHLIGHT.TOOLS_BUTTON) {
			setToolsOpen(true)
		}
	}, [tutorial.highlighted])
	if (!user) {
		return null
	}
	const handleShowCreateProjectTutorial = () => {
		if (allProjects.length) {
			changeTutorialField({
				modalAlignment: 'center',
				currentStep: 0,
				tutorialType: ETUTORIAL_TYPES.CREATE_PROJECT,
				modalOpen: true,
				maxSteps: 7,
			})
		} else {
			setSidenavState(false)
			resetProject()
			history.push(`${ROUTER_PATH.PROJECT_CREATION_WIZARD}0`)
		}
	}
	const handleClick = (routeTo: string) => {
		history.push(routeTo)
	}
	const translateIcon = (name: string) => {
		switch (name) {
			case analyse.DASHBOARD.description:
				return <DashboardIcon fontSize='medium' />
			case analyse.TASKS.description:
				return <AssignmentIcon fontSize='medium' />
			case manage.PROJECT_SETTINGS.description:
				return <Settings fontSize='medium' />
			case settings.PROFILE.description:
				return <AccountCircleIcon fontSize='medium' />
			case settings.SUBSCRIPTION.description:
				return <PaymentIcon fontSize='medium' />
			case settings.COMPANY.description:
				return <BusinessIcon fontSize='medium' />
			case settings.LOG_OUT.description:
				return <PowerSettingsNew fontSize='medium' />
			case 'Tools':
				return <BuildIcon fontSize='medium' />
			case 'create-project':
				return <AddIcon fontSize='medium' />
			case 'content-creator':
				return <CreateIcon fontSize='medium' />
			default:
				return null
		}
	}

	const handleLogOut = () => {
		logout()
		localStorage.removeItem('token')
		setTimeout(() => history.replace(ROUTER_PATH.LOGIN), 100)
	}
	const options = transformProjectsToOptions(allProjects)

	const handleSelectProject = async (item: any) => {
		try {
			setLoadingStart()
			resetTasks()
			const res = await getProjectById(item.value)
			await getProjectUser(item.value, res.payload.data.company.id)
		} catch (error) {
			console.error(error)
			throw error
		} finally {
			setLoadingEnd()
		}
	}

	const handleToolsDropDownOpen = () => {
		setToolsOpen(!toolsOpen)
	}

	const handleContentCreatorDropdownOpen = () => {
		setContentCreatorOpen(!contentCreatorOpen)
	}

	const closeModal = () => {
		setShowingModal(false)
	}

	const renderCustomItem = (item: IRouteObj) => {
		const shouldMakeActive =
			tutorial.highlighted === item.highlightKey || currentPath === item.route
		return (
			<ToolTip
				placement='right'
				title={t(
					'tooltips.sidenav explanations.' + _.toLower(item.description),
				)}
				delay={3000}
			>
				<div className='menu-item-holder'>
					<MenuItem
						key={item.description}
						onClick={() => handleClick(item.route)}
						keyValue={item.description}
						active={shouldMakeActive}
						to={item.route}
						icon={translateIcon(item.description)}
						description={t(item.translationKey)}
						//disabled={!!!currentProject.id | (item.route === ROUTER_PATH.AI_CONTENT_IN_BULK && ![ESubscriptionLevel.PREMIUM50, ESubscriptionLevel.ENTERPRISE].includes(user.company.subscriptionLevel))}
						//disabled={!!!currentProject.id}
						className={highlighter(tutorial.highlighted, item.highlightKey)}
						avatar={
							item.description === analyse.TASKS.description ? (
								<Avatar>
									{
										[
											...activeSuggestions,
											...pendingSuggestions,
											...pendingTasks,
											...activeTasks,
										].length
									}
								</Avatar>
							) : null
						}
						sidenavOpen={isSidenavOpen}
					/>
				</div>
			</ToolTip>
		)
	}
	const renderTools = () => Object.values(tools).map(renderCustomItem)
	const renderContentCreator = () =>
		Object.values(content_creation).map(renderCustomItem)
	const renderSettingsOptions = () => {
		return Object.values(settings).map(i => {
			if (i.description === settings.LOG_OUT.description) {
				return (
					<>
						<Divider />
						<div className='menu-item-holder'>
							<MenuItem
								key={i.description}
								onClick={handleLogOut}
								icon={translateIcon(i.description)}
								description={t(i.translationKey)}
								active={currentPath === i.route}
								sidenavOpen={isSidenavOpen}
							/>
						</div>
					</>
				)
			}

			return renderCustomItem(i)
		})
	}

	const renderManageOptions = () => {
		return isSidenavOpen ? Object.values(manage).map(renderCustomItem) : [null]
	}

	const sidenavHighlightOptions = [
		EHIGHLIGHT.SIDE_NAV,
		EHIGHLIGHT.ADS_VISUALISATION_BUTTON,
		EHIGHLIGHT.DASHBOARD_BUTTON,
		EHIGHLIGHT.KEYWORD_PLANNER_BUTTON,
		EHIGHLIGHT.KEYWORD_RANKING_BUTTON,
		EHIGHLIGHT.TECHNICAL_AUDIT_BUTTON,
		EHIGHLIGHT.TASKS_BUTTON,
		EHIGHLIGHT.TOOLS_BUTTON,
		EHIGHLIGHT.PROJECT_SETTINGS_BUTTON,
		EHIGHLIGHT.PROFILE_BUTTON,
		EHIGHLIGHT.SUBSCRIPTION_BUTTON,
		EHIGHLIGHT.CREATE_PROJECT_BUTTON,
	]

	const sidenavStyle = sidenavHighlightOptions.includes(tutorial.highlighted)
		? highlighter(EHIGHLIGHT.SIDE_NAV, EHIGHLIGHT.SIDE_NAV)
		: isSidenavOpen
		? 'open'
		: 'closed'

	const renderAnalyzeOptions = () =>
		Object.values(analyse).map((item: IRouteObj) => {
			return renderCustomItem(item)
		})
	const renderToolsDropdown = () => {
		if (!isSidenavOpen) {
			return [null]
		}
		const shouldMakeActive = tutorial.highlighted === EHIGHLIGHT.TOOLS_BUTTON
		return [
			<ToolTip
				key={'ToolsDropdown'}
				placement='right'
				title={t('tooltips.sidenav explanations.tools')}
				delay={3000}
			>
				<div className='menu-item-holder'>
					<MenuItem
						key={'tools'}
						onClick={handleToolsDropDownOpen}
						active={shouldMakeActive}
						icon={translateIcon('Tools')}
						description={t('sidenav.tools')}
						//disabled={!!!currentProject.id}
						extraOption={toolsOpen ? <ExpandLess /> : <ExpandMore />}
						className={highlighter(
							tutorial.highlighted,
							EHIGHLIGHT.TOOLS_BUTTON,
						)}
						sidenavOpen={isSidenavOpen}
					/>
				</div>
			</ToolTip>,
		]
	}
	const renderContentCreatorDropdown = () => {
		if (!isSidenavOpen) {
			return [null]
		}
		const shouldMakeActive = tutorial.highlighted === EHIGHLIGHT.TOOLS_BUTTON
		return [
			<ToolTip
				key={'ContentCreatorDropdown'}
				placement='right'
				title={t('tooltips.sidenav explanations.content tools')}
				delay={3000}
			>
				<div className='menu-item-holder'>
					<MenuItem
						key={'tools'}
						onClick={handleContentCreatorDropdownOpen}
						active={shouldMakeActive}
						icon={translateIcon('content-creator')}
						description={t('sidenav.content tools')}
						//disabled={!!!currentProject.id}
						extraOption={contentCreatorOpen ? <ExpandLess /> : <ExpandMore />}
						className={highlighter(
							tutorial.highlighted,
							EHIGHLIGHT.TOOLS_BUTTON,
						)}
						sidenavOpen={isSidenavOpen}
					/>
				</div>
			</ToolTip>,
		]
	}
	return (
		<Drawer
			variant='permanent'
			className={`sidenav ${sidenavStyle}`}
			anchor='left'
			ModalProps={{ keepMounted: true }}
			open={isSidenavOpen}
			transitionDuration={200}
		>
			<div
				className={`sidenav--close-switch ${isSidenavOpen ? 'open' : 'closed'}`}
			>
				<IconButton style={{ padding: 0 }} onClick={changeSidenavState}>
					<ChevronRightRounded style={{ fontSize: '2.5rem' }} />
				</IconButton>
			</div>
			{isSidenavOpen ? (
				<>
					<div className='sidenav-logo'>
						<img
							onClick={() => {
								handleClick(ROUTER_PATH.HOME)
							}}
							src={logo}
							alt='dashboa logo'
						/>
					</div>
					<div className='sidenav-title'>{t('sidenav.project')}</div>
					<div className='sidenav-project'>
						<Modal
							show={showingModal}
							closeModal={closeModal}
							header={'common.error modal title'}
							disabledClickOutside={true}
							modalContainerClassName='create-project'
						>
							<Createproject onProjectCreate={() => setShowingModal(false)} />
						</Modal>

						<SelectWithSearch
							name='project'
							options={options}
							value={
								currentProject.id
									? transformProjectToOptions(currentProject).options
									: {
											label: null,
											value: null,
									  }
							}
							defaultValue={transformProjectToOptions(currentProject).options}
							isDark
							onChange={handleSelectProject}
						/>
						<Button
							text={t('sidenav.create project')}
							onClick={handleShowCreateProjectTutorial}
							className={`${highlighter(
								tutorial.highlighted,
								EHIGHLIGHT.CREATE_PROJECT_BUTTON,
							)} dark-gray`}
							disabled={
								currentProjectAmounts.numberOfActiveProject >=
								currentProjectAmounts.max
							}
						/>
						{currentProjectAmounts.numberOfActiveProject >=
							currentProjectAmounts.max && (
							<p className='sidenav-project--max-projects'>
								{t('sidenav.upgrade your subscription level for more projects')}
							</p>
						)}
					</div>
					<div className='sidenav-title'>{t('sidenav.analyze')}</div>
					<MenuItemContainer items={renderAnalyzeOptions()} />
					<MenuItemContainer items={renderToolsDropdown()} />
					<Collapse
						in={toolsOpen}
						timeout='auto'
						collapsedSize={`${Object.keys(analyse).length * 75}px`}
						unmountOnExit
						style={{ minHeight: 'unset' }}
					>
						<MenuItemContainer
							className={'sidenav-tools'}
							items={renderTools()}
						/>
					</Collapse>
					<MenuItemContainer items={renderContentCreatorDropdown()} />
					<Collapse
						in={contentCreatorOpen}
						timeout='auto'
						collapsedSize={`${Object.keys(analyse).length * 75}px`}
						unmountOnExit
						style={{ minHeight: 'unset' }}
					>
						<MenuItemContainer
							className={'sidenav-tools'}
							items={renderContentCreator()}
						/>
					</Collapse>
					<div className='sidenav-title manage'>{t('sidenav.manage')}</div>
					<MenuItemContainer items={renderManageOptions()} />
					<div className='sidenav-settings'>
						<MenuItemContainer
							className='sidenav-settings__container'
							items={renderSettingsOptions()}
						/>
					</div>
					<PrivacyPolicyLink className='sidenav' />
				</>
			) : (
				<>
					{' '}
					<div className='sidenav-logo small'>
						<img
							src={smallLogo}
							alt='logo'
							onClick={() => {
								handleClick(ROUTER_PATH.HOME)
							}}
						/>
					</div>
					<MenuItemContainer items={renderAnalyzeOptions()} />
					<Divider />
					<MenuItemContainer
						className={'sidenav-tools no-items'}
						items={[null]}
					/>
					<MenuItemContainer items={renderManageOptions()} />
					<Divider />
					<MenuItemContainer
						className='sidenav-settings__container'
						items={renderSettingsOptions()}
					/>
				</>
			)}
		</Drawer>
	)
}

export default connect(
	(state: any) => ({
		user: userSelectors.getUser(state.user),
		firebaseToken: state.user.firebaseToken,
		company: userSelectors.getUserCompany(state.user),
		isSidenavOpen: userSelectors.isSidenavOpen(state.user),
		allProjects: projectSelectors.getAllProjects(state.project),
		authLoading: loadingSelectors.getAuthLoading(state.loading),
		isAuthenticated: userSelectors.isAuthenticated(state.user),
		currentProject: projectSelectors.getProject(state.project),
		dashboard: dashboardSelectors.getAllDashboardCharts(state.dashboard),
		allSuggestions: dashboardSelectors.getSuggestions(state.dashboard),
		activeSuggestions: dashboardSelectors.getActiveSuggestions(state.dashboard),
		pendingSuggestions: dashboardSelectors.getPendingSuggestions(
			state.dashboard,
		),
		activeTasks: taskSelectors.getActiveTasks(state.task),
		pendingTasks: taskSelectors.getPendingTasks(state.task),
		tutorial: tutorialSelectors.getTutorial(state.tutorial),
		allCountries: state.keywords.allCountries,
		currentProjectAmounts: projectSelectors.getCheckProjecAmount(state.project),
	}),
	dispatch => ({
		selectProject: (data: IProject) =>
			dispatch(projectActions.selectProject(data)),
		getProjectById: (projectId: number) =>
			dispatch(projectActions.getProjectById(projectId)),
		getProjectUser: (projectId: number, companyId: number) =>
			dispatch(userActions.getProjectUser(projectId, companyId)),
		logout: () => dispatch(userActions.signOut()),
		resetTasks: () => dispatch(projectActions.resetDashboard()),
		resetProject: () => dispatch(projectActions.resetProjectState()),
		getSuggestionsAll: (projectId: number) =>
			dispatch(projectActions.getSuggestions(projectId)),
		getAllTasks: (projectId: number) =>
			dispatch(taskActions.getAllTasks(projectId)),
		changeTutorialField: (payload: Partial<TutorialState>) =>
			dispatch(tutorialActions.changeTutorialField(payload)),
		getCountries: () => dispatch(projectActions.getCountries()),
		checkProjectAmount: () => dispatch(projectActions.checkProjectAmount()),
		setLoadingStart: () => dispatch(startLoading()),
		setLoadingEnd: () => dispatch(stopLoading()),
		getFirebaseToken: () => dispatch(getUserFirebaseToken()),
		changeSidenavState: () => dispatch(userActions.changeSidenavStatus()),
		setSidenavState: (isExpanded: boolean) =>
			dispatch(userActions.setSidenavStatus(isExpanded)),
	}),
)(SideNav)
