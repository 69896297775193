import React from 'react'
import { useTranslation } from 'react-i18next'
import { privacyPolicy } from '../../constants'

type Props = {
	className?: string
}

export const PrivacyPolicyLink = ({ className }: Props): JSX.Element => {
	const { t } = useTranslation()
	const privacyClasses = className
		? 'privacy-policy '.concat(className)
		: 'privacy-policy '
	return (
		<div className={privacyClasses}>
			<a href={privacyPolicy} onClick={() => window.open(privacyPolicy)}>
				<p className='privacy-policy__text'>{t('common.privacy policy')}</p>
			</a>
		</div>
	)
}
