/**
 * Root client file
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

// WORK INSTRUCTIONS:
// - Please do not remove the above header. It protects the IPR intrests of our company.
// - Please replace the author name with your own full real name.

// React stuffs
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import './services/i18n'
import { store, persistor } from './store'
import packageInfo from '../package.json'

// history from reducer
import { history } from './reducers'

// Styling
import './styles/index.scss'

// Components
import App from './components/App'
import reportWebVitals from './components/reportWebVitals'
import TagManager from 'react-gtm-module'

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_API_KEY,
		integrations: [new Integrations.BrowserTracing()],
		environment: process.env.REACT_APP_SENTRY_ENV || 'development',
		release:
			'dashboa@' + packageInfo.version || process.env.npm_package_version,

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	})
	const tagManagerArgs = {
		gtmId: 'GTM-5KKSRR2',
		// gtmId: 'GTM-WQHR643',
	}

	TagManager.initialize(tagManagerArgs)
}

const props = {
	history,
}

const container = document.getElementById('app')
const root = ReactDOM.createRoot(container!)
root.render(
	<Provider store={store}>
		<ConnectedRouter {...props}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</ConnectedRouter>
	</Provider>,
)
